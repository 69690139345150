<template>
  <div style="display: flex">
<!--    <contract-side-menu></contract-side-menu>-->
    <div class="draftContractContainer">
        <div class="draftContractTitle">起草方式一：通过模板起草</div>
        <el-tabs v-model="contractBank" @tab-click="switchTab"  type="border-card">
            <el-tab-pane label="团队合同库" :name="BankName.TEAM">
                    <el-scrollbar style="height: 350px">
                        <div class="categoryTemplate" v-for="c in teamTemplateCategories" :key="c.id">
                            <h3>{{c.name}}</h3>
                            <div class="template-list">
                                <div class="templateItem"  v-for="template in teamTemplateMap.get(c.id)"  :key="template.id">
                                    <div class="template-icon"></div>
                                    <div class="template-name">{{template.title}}</div>
                                    <div class="cover">
                                        <div class="cover-container" :title="template.description">
                                            <div class="name" @click="startToAnswerQuestion(template.id)">立即起草</div>
                                            <div class="name" v-if="userInfo&&userInfo.isManagerRole" @click="previewTemplate(template.fileId,template.title)">编辑模版</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="MetaGo合同库" :name="BankName.SYSTEM">
                <el-scrollbar style="height: 350px">
                    <div class="categoryTemplate" v-for="c in systemTemplateCategories" :key="c.id">
                        <h3>{{c.name}}</h3>
                        <div class="template-list">
                            <div class="templateItem"   v-for="template in systemTemplateMap.get(c.id)"  :key="template.id">
                                <div class="template-icon"></div>
                                <div class="template-name">{{template.title}}</div>
                                <div class="cover">
                                    <div class="cover-container" :title="template.description">
                                        <div class="name"  @click="startToAnswerQuestion(template.id)">立即起草</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </el-tab-pane>
        </el-tabs>
        <div class="draftContractTitle">起草方式二：上传外部文件起草</div>
      <div style="text-align: center">
        <span>设置为：</span>
        <el-radio v-model="contractStatus" :label="ContractApprovalStatus.DRAFT">草稿</el-radio>
        <el-radio v-model="contractStatus" :label="ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED">定稿</el-radio>
      </div>
        <div class="contractUploader">
            <el-upload
                    class="upload-demo"
                    drag
                    accept=".docx,.DOCX,.pdf,.PDF,.doc,.DOC"
                    :show-file-list="false"
                    :on-success="uploadSuccessFunc"
                    :on-error="uploadErrorFunc"
                    :before-upload="beforeUploadFunc"
                    :action="uploadUrl"
                    :data="{status:contractStatus}"
                    multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将合同文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
        </div>

    </div>
  </div>
</template>
<script>
import {
  createShareParam,
  getContractPreviewStatus,
  getSystemTemplateBank,
  getTeamTemplateBank,
} from "../../api/api";
    import config from "../../config";
    import {ElLoading} from "element-plus";
    import ContractSideMenu from "../../components/ContractSideMenu";
    import {ContractApprovalStatus} from "../../constant/contract";
// 模板库名
    const BankName = {
        TEAM:'team',
        SYSTEM:'system'
    }
    // 文件处理状态
    const FileJobStatusType = {
        UNTREATED: 0,
        PROCESSING: 1,
        FINISH: 2,
        //错误状态
        UPDATE_ES_ERROR: 11,
        FILE_IS_DELETED: 12,
        DELETE_ES_ERROR: 13,
        OLD_OPERATION: 14,
        FIND_FILECACHE_TIME_OUT: 15,
        TASK_ERROR: 16,
        EXTRACT_CONTENT_ERROR: 17,
        SEG_ERROR: 19,
        EMPTY_CONTENT: 21,
        SEG_CLASSIFY_ERROR: 22,
        SEG_ERROR_RETRY_LIMIT: 109
    }
    export default {
        name: "DraftContract",
      components: {ContractSideMenu},
      data(){
            return {
                contractBank:'team',
                BankName:BankName,
                loadingObj:{},
                loadSuccessObj:{},
                teamTemplates:[],
                teamTemplateCategories:[],
              ContractApprovalStatus,

                systemTemplates:[],
                systemTemplateCategories:[],

                createContractFreeze:false,
                uploadUrl: config.baseUrl + 'contract/upload/draft',
                loadingInstance:null,
                queryConvertStatusTimeout:null,
                contract:null,
                contractStatus:ContractApprovalStatus.DRAFT,
                userInfo:JSON.parse(sessionStorage.getItem("userInfo"))
            }
        },
        mounted() {
            this.loadData(this.contractBank);
        },
        computed:{
            teamTemplateMap(){
                let map = new Map();
                this.teamTemplates.forEach((template)=>{
                    if (!map.has(template.categoryId)) {
                        map.set(template.categoryId,[])
                    }
                    map.get(template.categoryId).push(template);

                })
                return map;
            },
            systemTemplateMap(){
                let map = new Map();
                this.systemTemplates.forEach((template)=>{
                    if (!map.has(template.categoryId)) {
                        map.set(template.categoryId,[])
                    }
                    map.get(template.categoryId).push(template);
                })
                return map;
            },
        },
        methods:{
            switchTab(tab) {
                this.loadData(tab.paneName);
            },
            loadData:function (bankName) {
                if (this.loadingObj[bankName] || this.loadSuccessObj[bankName]) {
                    return;
                }
                this.loadingObj[bankName] = true;
                this.requestBankData(bankName).then(res => {
                    console.log('loadData', res)
                    if (res.data.code === 0) {
                        this.loadSuccessObj[bankName] = true;
                        switch (bankName) {
                            case BankName.TEAM:
                                this.teamTemplates = res.data.data.templates;
                                this.teamTemplateCategories = res.data.data.categories;
                                break;
                            case BankName.SYSTEM:
                                this.systemTemplates = res.data.data.templates;
                                this.systemTemplateCategories = res.data.data.categories;
                                break;
                        }
                    }
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    this.loadingObj[bankName] = false;
                });
            },
            requestBankData:function (bankName) {
                if (bankName === BankName.TEAM) {
                    return getTeamTemplateBank();
                }else if (bankName === BankName.SYSTEM){
                    return getSystemTemplateBank();
                }
            },
            /**
             * 获取答题需要数据，进入答题界面
             */
            startToAnswerQuestion: function (templateId) {
                if (this.createContractFreeze) {
                    return;
                }
                this.createContractFreeze = true;

                createShareParam(templateId).then(res => {
                    if (res.data.code === 0) {
                        let key = res.data.data
                        this.jumpToAnswerQuestion(key);
                    }
                }).catch(error => {
                    console.error(error);
                }).finally(()=>{
                    this.createContractFreeze = false;
                });
            },
            beforeUploadFunc:function(){
                this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
                this.loadingInstance.setText('上传中')
            },
            uploadSuccessFunc:function (res,file,fileList) {
                if (res.code === 0) {
                    this.loadingInstance.setText('上传成功')
                    console.log('uploadSuccess', res);
                    this.contract = res.data;
                    this.queryContractConvertStatus(this.contract);

                }else{
                    this.$message.error('上传失败');
                    this.loadingInstance.close();
                }
            },
            uploadErrorFunc: function (err, file, fileList){
                this.$message.error('上传失败');
                this.loadingInstance.close();
            },
            jumpToContractDetail: function (id, title) {
                this.$router.push({name: 'contractView', query: {id: id, name: title}})
            },
            queryContractConvertStatus:function(contract){
                if (this.queryConvertStatusTimeout) {
                    clearTimeout(this.queryConvertStatusTimeout)
                }
                this.queryConvertStatusTimeout = setTimeout(()=>{
                  getContractPreviewStatus(contract.id).then(res => {
                        if (res.data.code === 0) {
                            let canPreview = res.data.data;
                            if (!canPreview) {
                                this.loadingInstance.setText('转换中')
                                // 继续轮询
                                this.queryContractConvertStatus(contract);
                            }else{

                                this.loadingInstance.close();
                                this.jumpToContractDetail(contract.id,contract.title)
                            }
                        }else {
                            console.error("res.data:",res.data)
                            this.loadingInstance.setText('转换失败')
                            this.loadingInstance.close();
                            this.jumpToContractDetail(contract.id,contract.title)
                        }
                    }).catch(error => {
                        this.loadingInstance.setText('转换失败')
                        this.loadingInstance.close();
                        this.jumpToContractDetail(contract.id,contract.title)
                        console.error(error);
                    });
                },500)
            },
            /**
             * 跳到答题界面
             * @param dataKey
             */
            jumpToAnswerQuestion:function (dataKey) {
                let resolveObj = {
                    name: 'answerQuestion',
                    query: {
                        data: dataKey
                    }
                };
                this.$router.push(resolveObj);
            },
            previewTemplate(fileId,fileName){
              this.$router.push({name:'doc',query:{fileId:fileId,fileName:fileName}})
            }
        },

    }
</script>

<style lang="scss">
    .draftContractContainer{
        text-align: left;
        padding:10px 20px;
      width: 100%;
    }
    .draftContractTitle{
        line-height: 2;
        margin: 10px 0;
    }
    .template-list{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .templateItem{
        width: 85px;
        text-align: center;
        border: #efefef solid 1px;
        border-radius: 8px;
        position: relative;
        padding:10px;
        margin: 5px;
        cursor: pointer;
        &:hover{
            .cover{
                visibility: visible;
            }
        }
        .template-icon{
            background: url(../../assets/images/icon_wj.png) center /contain no-repeat;
            width: 33px;
            height: 35px;
            margin: auto;
        }
        .template-name{
            font-size: 14px;
            color: #000;
            font-weight: 600;
            /*white-space: nowrap;*/
            overflow: hidden;
            margin: 8px auto 0;
            text-align: center;
        }
        .cover{
            position: absolute;
            visibility: hidden;
            background-color: #1C4DC5;
            border-radius: 8px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: white;
            text-align: left;
            .cover-container {
                padding: 11px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .name {
                  border-radius: 5px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: bold;
                  /*white-space: nowrap;*/
                  overflow: hidden;
                  padding: 5px;
                }
                .name:hover{
                  background-color: rgba(77, 114, 201, 0.9);
                }
                .description {
                    padding: 5px;
                    font-size: 12px;
                    word-break: break-all;
                    margin-top: 11px;
                    color: #ffffff9f;
                }
                .entry-icon {
                    width: 24px;
                    height: 24px;
                    background: url(../../assets/images/icon_xy.png) center;
                    background-size: 49px;
                    position: absolute;
                    right: 14px;
                    bottom: 14px;
                }
            }
        }

    }
    .contractUploader{
        padding:15px 0;
        display: flex;
        justify-content: center;
    }
</style>
<style scoped>

</style>
